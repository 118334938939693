<template>
  <div class="advert">
    <PageHeader />
    <div class="header">
      <Header />
      <div class="header-text">
        <div class="header-title">
          广告栏目 
        </div>
        <div class="header-sign">
          CPL、CPA、ASO等多种合作方式
        </div>
      </div>
    </div>
    <div class="content">
      <div class="section">
        <div class="section-title">
          CPL
        </div>
        <div class="section-line" />
        <div class="section-desc">
          新量象拥有CPL业内前3的用户量，单个游戏单日新用户可达5000+，月新增高质量用户可达10万+；高质量用户结合独有运营手段，为CPL合作伙伴创造丰厚的回报，优质游戏回收率可达200%+；从方案制定到技术对接，从用户新增到充值回收，专业数据分析、运营、技术团队提供全程监控与指导。
        </div>
        <div class="section-list">
          <div
            class="cpl-item"
            :style="{backgroundImage: `url(${item.bg})`}"
            v-for="(item, index) in cplList"
            :key="index"
          >
            <div class="cpl-item-top">
              <img
                :src="item.icon"
                alt=""
                class="cpl-item-icon"
              >
              <div class="cpl-item-info">
                <div class="cpl-item-name">
                  {{ item.name }}
                </div>
                <div class="cpl-item-desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
            <div class="cpl-item-down">
              <div
                class="cpl-item-sign"
                v-for="(v, i) in item.signs"
                :key="i"
              >
                {{ v }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">
          CPA
        </div>
        <div class="section-line" />
        <div class="section-desc">
          新量象cpa投放广告，覆盖用户范围广，广告主每一分钱投入都发挥极大效果，我们拥有丰富的平台资源，能够精准定向目标客户，日均展示总量近亿次 。专业的服务和专注的用户运营让您业务拓展更顺利。
        </div>
        <div class="section-list">
          <div
            class="cpa-item"
            v-for="(item, index) in cpaList"
            :key="index"
          >
            <img
              :src="item.icon"
              alt=""
              class="cpa-item-icon"
            >
            <div class="cpa-item-info">
              <div class="cpa-item-money">
                <div class="cpa-item-num">
                  {{ item.money1 }}
                </div>
                <div class="cpa-item-name">
                  {{ item.name1 }}
                </div>
              </div>
              <div class="cpa-item-line" />
              <div class="cpa-item-money">
                <div class="cpa-item-num">
                  {{ item.money2 }}
                </div>
                <div class="cpa-item-name">
                  {{ item.name2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">
          ASO
        </div>
        <div class="section-line" />
        <div class="section-desc">
          新量象ASO优化帮助15款应用进入免费总榜TOP5、帮助103款进入类目榜TOP3，来自业内顶级团队的资深优化师全程监控、优化。百万真实用户资源、精细化标签分配、冲榜与优化事半功倍，提供一站式专业服务，一对一客户服务专员24小时解答。
        </div>
        <div class="section-list">
          <div
            class="aso-item"
            v-for="(item, index) in asoList"
            :key="index"
          >
            <img
              :src="item.icon"
              alt=""
              class="aso-item-icon"
            >
            <div class="aso-item-sign">
              <div
                v-for="(v,i) in item.signs"
                :key="i"
              >
                {{ v }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">
          新媒体
        </div>
        <div class="section-line" />
        <div class="section-desc">
          新量象新媒体服务，内容涉及淘宝、抖音、微信公众号及资讯类，覆盖美妆、教育、生活等领域，通过独到、趣味的原创内容，新量象打造具有受众影响力的自媒体矩阵，受到不同年龄层用户的欢迎。
        </div>
        <div class="section-list">
          <div
            class="news-item"
            v-for="(item, index) in newsList"
            :key="index"
          >
            <img
              :src="item.icon"
              alt=""
              class="news-item-icon"
            >
            <div class="news-item-name">
              {{ item.name }}
            </div>
            <div class="news-item-desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <img
        src="../img/footer-line.png"
        alt=""
        class="footer-line"
      >
      <PageFooter />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  data () {
    return {
      cplList: [
        {
          bg: require('../img/cpl-bg1.png'),
          icon: require('../img/cpl-icon1.png'),
          name: '蛮荒纪元',
          desc: '魔化风格的RPG手游，全3D技术打造！',
          signs: ['导入用户数量15W+', '2019年1月合作至今', 'ROI 1:3']
        },
        {
          bg: require('../img/cpl-bg5.png'),
          icon: require('../img/cpl-icon5.png'),
          name: '斗罗大陆',
          desc: '原创策略玩法，多武魂觉醒，万千魂环搭配，与你创造斗罗大陆的新篇章！',
          signs: ['导入用户数量10W+', '2019年4月合作至今', 'ROI 1:3.1']
        },
        {
          bg: require('../img/cpl-bg6.png'),
          icon: require('../img/cpl-icon6.png'),
          name: '热血之刃',
          desc: '加入《热血之刃》与兄弟并肩征战，重温旧梦！向青春致敬！',
          signs: ['导入用户数量10W+', '2019年6月合作至今', 'ROI 1:2.5', '日活5W+']
        },
        {
          bg: require('../img/cpl-bg4.png'),
          icon: require('../img/cpl-icon4.png'),
          name: '凡人飞仙传',
          desc: '最爽快的修仙者战斗，最精彩的仙侠世界',
          signs: ['导入用户数量10W+', '2019年9月合作至今', 'ROI 1:2.6']
        }
      ],
      cpaList: [
        {
          icon: require('../img/cpa-icon1.png'),
          name1: '日均注册',
          money1: '10万+',
          name2: '高质量用户',
          money2: '6000万+'
        },
        {
          icon: require('../img/cpa-icon2.png'),
          name1: '日均注册',
          money1: '7.5万+',
          name2: '高质量用户',
          money2: '7500万+'
        },
        {
          icon: require('../img/cpa-icon3.png'),
          name1: '日均注册',
          money1: '6万+',
          name2: '高质量用户',
          money2: '5600万+'
        },
        {
          icon: require('../img/cpa-icon4.png'),
          name1: '日均注册',
          money1: '8万+',
          name2: '高质量用户',
          money2: '4000万+'
        },
        {
          icon: require('../img/cpa-icon5.png'),
          name1: '日均注册',
          money1: '8万+',
          name2: '高质量用户',
          money2: '5000万+'
        },
        {
          icon: require('../img/cpa-icon6.png'),
          name1: '日均注册',
          money1: '10万+',
          name2: '高质量用户',
          money2: '6500万+'
        },
      ],
      asoList: [
        {
          icon: require('../img/aso-icon1.png'),
          signs: [
            '- 日榜前三',
            '- 新用户下载量15w+',
            '- 实现30+关键词App Store优化'
          ]
        },
        {
          icon: require('../img/aso-icon2.png'),
          signs: [
            '- 高用户覆盖率',
            '- 新用户下载量20w+',
            '- 实现30+关键词App Store优化'
          ]
        },
        {
          icon: require('../img/aso-icon3.png'),
          signs: [
            '- 日榜前三',
            '- 新用户下载量10w+',
            '- 实现30+关键词App Store优化'
          ]
        },
        {
          icon: require('../img/aso-icon4.png'),
          signs: [
            '- 日榜前三',
            '- 自然用户下载激增',
            '- 实现30+关键词appstore优化'
          ]
        }
      ],
      newsList: [
        {
          icon: require('../img/news-icon1.png'),
          name: '淘宝',
          desc: '提供淘宝直播代播；淘宝直播代运营；大主播带货；淘宝电商培训；货品供应 链合作服务。',
        },
        {
          icon: require('../img/news-icon2.png'),
          name: '抖 音',
          desc: '提供抖音评论；抖音用户；抖音点赞；抖音视频剪辑；抖音短视频服务。',
        },
        {
          icon: require('../img/news-icon3.png'),
          name: '文章代发',
          desc: '提供新浪、搜狐、网易等多平台文章代发；IT、游戏、财经类文章代发等。',
        },
        {
          icon: require('../img/news-icon4.png'),
          name: '微信公众号',
          desc: '提供微信公众号用户；微信公众号阅读 量；微信公众号在看数；微信公众号流量主开通服务。',
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url('../img/advert-bg.png');
  background-size: cover;
}
.header-text{
  position: absolute;
  top: 166px;
  left: 260px;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  // font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color: #fff;
}
.content{
  padding: 0 260px;
}
.footer-line{
  width: 1400px;
  height: 14px;
  margin-bottom: 60px;
  object-fit: cover;
}
.section{
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  &-title{
    font-size:76px;
    color:rgba(68,68,68,1);
    height: 54px;
    margin-bottom: 27px;
    // font-family: 'fzqk';
  }
  &-desc{
    font-size:18px;
    color:rgba(102,102,102,1);
    line-height:36px;
    margin-bottom: 51px;
    text-indent: 2em;
  }
  &-line{
    width:80px;
    height:8px;
    background:linear-gradient(90deg,rgba(255,114,0,1),rgba(255,162,0,1));
    margin-bottom: 41px;
  }
  &-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.cpl-item{
  width: 640px;
  height: 300px;
  margin-bottom: 60px;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 148px;
  padding-left: 22px;
  &-top{
    margin-bottom: 18px;
  }
  &-top,&-down{
    display: flex;
    align-items: center;
  }
  &-icon{
    width: 80px;
    height: 80px;
    margin-right: 18px;
  }
  &-name{
    font-size:28px;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:28px;
    margin-bottom: 17px;
  }
  &-desc{
    font-size:16px;
    color:rgba(255,255,255,1);
    line-height:16px;
  }
  &-sign{
    padding: 0 14px;
    height:32px;
    line-height: 32px;
    border:1px solid rgba(255, 255, 255, 1);
    border-radius:16px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 16px;
    margin-right: 16px;
  }
  &-sign:nth-child(1){
    background:linear-gradient(90deg,rgba(255,161,0,1),rgba(255,115,0,1));
  }
}
.cpa-item{
  width:440px;
  height:300px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 14px 0px rgba(4,0,0,0.06);
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  &-icon{
    height: 80px;
    margin: 56px 0 51px;
  }
  &-info{
    display: flex;
    align-items: center;
  }
  &-num{
    font-size:30px;
    font-weight:bold;
    color:rgba(255,132,0,1);
    line-height:30px;
    margin-bottom: 20px;
  }
  &-name{
    font-size:14px;
    color:rgba(153,153,153,1);
    line-height:14px;
  }
  &-line{
    width:1px;
    height:50px;
    background:rgba(230,230,230,1);
    margin-left: 66px;
    margin-right: 45px;
  }
}
.aso-item{
  width:650px;
  height:200px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 14px 0px rgba(4,0,0,0.06);
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  &-icon{
    width: 220px;
    object-fit: cover;
    margin-left: 40px;
    margin-right: 50px;
  }
  &-sign{
    font-size:20px;
    color:rgba(255,132,0,1);
    line-height:44px;
  }
}
.news-item{
  width:320px;
  height:220px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 14px 0px rgba(4,0,0,0.06);
  margin-bottom: 140px;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 30px;
  margin-top: 40px;
  &-icon{
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-bottom: 25px;
  }
  &-name{
    font-size:24px;
    color:rgba(255,132,0,1);
    line-height:24px;
    margin-bottom: 15px;
    // font-family: 'fzqk';
  }
  &-desc{
    font-size:14px;
    color:rgba(102,102,102,1);
    line-height:30px;
  }
}
@media screen and (max-width: 1440px) {
  .header{
    height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 195px;
  }
  .footer-line{
    width: 1050px;
    height: 11px;
    margin-bottom: 44px;
  }
  .section{
    padding-top: 78px;
    &-title{
      font-size:56px;
      color:rgba(68,68,68,1);
      height: 40px;
      margin-bottom: 16px;
    }
    &-desc{
      font-size:14px;
      color:rgba(102,102,102,1);
      line-height:27px;
      margin-bottom: 38px;
    }
    &-line{
      width:60px;
      height:6px;
      background:linear-gradient(90deg,rgba(255,114,0,1),rgba(255,162,0,1));
      margin-bottom: 30px;
    }
  }
  .cpl-item{
    width: 480px;
    height: 224px;
    margin-bottom: 46px;
    padding-top: 111px;
    padding-left: 17px;
    &-top{
      margin-bottom: 14px;
    }
    &-icon{
      width: 60px;
      height: 60px;
      margin-right: 13px;
    }
    &-name{
      font-size:21px;
      line-height:21px;
      margin-bottom: 11px;
    }
    &-desc{
      font-size:14px;
      line-height:14px;
    }
    &-sign{
      padding: 0 8px;
      height:24px;
      font-size: 12px;
      line-height: 24px;
      border-radius:12px;
      margin-right: 12px;
    }
  }
  .cpa-item{
    width:330px;
    height:226px;
    background:rgba(255,255,255,1);
    box-shadow:0px 0px 11px 0px rgba(4,0,0,0.06);
    margin-bottom: 30px;
    &-icon{
      height: 60px;
      margin: 42px 0 40px;
    }
    &-num{
      font-size:23px;
      line-height:23px;
      margin-bottom: 12px;
    }
    &-name{
      font-size:12px;
      line-height:12px;
    }
    &-line{
      width:1px;
      height:38px;
      margin-left: 49px;
      margin-right: 32px;
    }
  }
  .aso-item{
    width:488px;
    height:150px;
    background:rgba(255,255,255,1);
    box-shadow:0px 0px 11px 0px rgba(4,0,0,0.06);
    margin-bottom: 45px;
    &-icon{
      width: 164px;
      margin-left: 30px;
      margin-right: 38px;
    }
    &-sign{
      font-size:16px;
      line-height:33px;
    }
  }
  .news-item{
    width:240px;
    height:164px;
    background:rgba(255,255,255,1);
    box-shadow:0px 0px 11px 0px rgba(4,0,0,0.06);
    margin-bottom: 106px;
    box-sizing: border-box;
    padding-left: 26px;
    padding-right: 18px;
    margin-top: 30px;
    &-icon{
      width: 60px;
      height: 60px;
      margin-top: -30px;
      margin-bottom: 17px;
    }
    &-name{
      font-size:18px;
      line-height:18px;
      margin-bottom: 12px;
    }
    &-desc{
      font-size:12px;
      line-height:22px;
    }
  }
}
</style>